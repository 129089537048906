<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-custom card-stretch gutter-b">
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark"
            >Datos del curso</span
            >
                    </h3>
                    <div class="card-toolbar">
                        <a
                                @click="goList"
                                class="btn btn-secondary font-weight-bolder font-size-sm mr-3"
                        >
                            Volver
                        </a>
                    </div>
                </div>
                <div class="card-body pb-10 pb-2">
                    <div class="row ml-2 mt-2">
                        <div class="col-md-2">
                            <img :src="selectedItem.image" class="image-view" alt=""/>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="label-detail">Nombre</div>
                                    <div>{{ selectedItem.name }}</div>
                                </div>
                                <div class="col-md-12">
                                    <div class="label-detail">Descripción</div>
                                    <div>{{ selectedItem.description }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ml-2">
                        <div class="col-md-12">
                            <div class="label-detail">Reseña</div>
                            <div class="html-container">
                                <div v-html="selectedItem.review"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import {COURSES_GET_COURSES_LIST} from "@/store/modules/courses.module"

    export default {
        name: "CoursesDetail",
        data() {
            return {};
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Cursos", route: "courses"},
                {title: "Datos del curso"},
            ]);
        },
        methods: {
            goList() {
                this.$router.push("courses");
            },
        },
        computed: {
            selectedItem() {
                return this.$store.state.courses.selectedCourse;
            },
        },
    };
</script>

<style scoped></style>
